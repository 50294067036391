<template>
  <div id="dashboard-page" class="w-full">
    <div class="d-flex align-center justify-space-between">
      <v-breadcrumbs :items="breadcrumbList" class="pl-0 config-breadcrumbs">
        <template v-slot:item="{ item }">
          <v-breadcrumbs-item @click.native="!item.disabled && $router.push(item.href)" class="primary--text"
            :class="{'secondary--text': breadcrumbList.length > 1 && item.disabled}">
            {{ item.text }}
          </v-breadcrumbs-item>
        </template>
        <template v-slot:divider>
          <v-icon>mdi-chevron-right</v-icon>
        </template>
      </v-breadcrumbs>
    </div>
    <AdvanceFilter @search="searchFilter" @reset="filterData = null;getFilterAPI()" :loading="loading"
      @remove="searchFilter($event, 'remove')"/>
    <h5 class="text-center mt-10"> {{ scanDate }} Scans</h5>
    <v-row class="w-full h-full mt-10" justify="center" align="center">
        <v-col cols="12" xl="6" lg="6" class="py-0">
            <h6 class="text-center mt-6">Agent's</h6>
            <v-row class="ma-0 w-100" align="center" justify="center" v-if="this.model.length == 0 || loading" style="height: 300px">
                <h6 class="black--text" v-if="!loading">Data Not Available!.<v-icon color="warning" class="mb-2 ml-2">mdi-alert</v-icon></h6>
                <div class="spinner-design-loader" v-else></div>
            </v-row>
            <v-card v-else class="pa-0 ma-0">
                <div ref="chartdiv" class="w-100" style="height: 500px"></div>
            </v-card>
        </v-col>
        <v-divider class="d-flex d-xl-none d-lg-none d-my-7"/>
        <v-col cols="12" xl="6" lg="6" class="py-0">
            <h6 class="text-center mt-6">Location's</h6>
            <v-row class="ma-0 w-100" align="center" justify="center" v-if="this.locationModel.length == 0 || locationLoading" style="height: 300px">
                <h6 class="black--text" v-if="!locationLoading">Data Not Available!.<v-icon color="warning" class="mb-2 ml-2">mdi-alert</v-icon></h6>
                <div class="spinner-design-loader" v-else></div>
            </v-row>
            <v-card v-else class="pa-0 ma-0">
                <div ref="locationChartdiv" class="w-100" style="height: 500px"></div>
            </v-card>
        </v-col>
    </v-row>
  </div>
</template>

<script>
import _ from 'lodash';
import * as am5 from '@amcharts/amcharts5';
import * as am5xy from '@amcharts/amcharts5/xy';
import am5themes_Animated from '@amcharts/amcharts5/themes/Animated';
import { mapActions } from 'vuex';

export default {
  components: {
    AdvanceFilter: () => import("./components/AdvanceFilter"),
  },
  data: (vm) => ({
    breadcrumbList: [{
      text: 'Scan N Go Analytics',
      disabled: true,
      href: '/',
    }],
    chart: '',
    locationChart: '',
    model: [],
    locationModel:[],
    loading: false,
    locationLoading: false,
    filterData: null,
  }),
  computed: {
    scanDate() {
        const currDate = this.$helpers.getMomentDatas('YYYY-MM-DD');
        return !this.filterData || (this.filterData && this.filterData.length && this.filterData.every(item => item && item== currDate))
            ? `Today's` : `${this.filterData.date[0]} ${this.filterData.date[1] && this.filterData.date[0] !== this.filterData.date[1] ? ' - '+ this.filterData.date[1] : ''}`;
    }
  },
  created() {
    this.getAgentModel();
    this.getLocationModel();
  },
  mounted() {
    if (!this.loading && this.model.length) this.chartInitiated();
    if (!this.locationLoading && this.locationModel.length) this.locationChartInitiated();
  },
  methods: {
    ...mapActions(['getScanModel']),


    searchFilter(data, type) {
        if (type) {
            if (this.filterData && this.filterData[data]) {
                this.filterData[data] = null;
                if(data === 'agent_id' || data === 'location_id') this.filterData[data.split('_')[0]] = null;
            }
            return;
        }
        this.filterData = data;
        this.getFilterAPI();
    },
    async getFilterAPI() {
        let params = {};
        if(this.filterData) {
            const keyFilter = _.filter(Object.keys(this.filterData), key => this.filterData[key]);
            await _.map(keyFilter, key => {
                if(key ==='date') {
                    params.ts = this.filterData[key][0];
                    params.td = this.filterData[key][1];
                } else params[key] = this.filterData[key];
            });
        }
        this.getAgentModel(params);
        this.getLocationModel(params);
    },
    getAgentModel(params = {}) {
        this.loading = true;
        this.getScanModel({ endPoint: 'agent-statistics', params }).then(resp => {
        this.model = resp || [];
        this.loading = false;
        setTimeout(() => {
            this.chartInitiated();
        });
        }).catch(err => this.loading = false);
    },
    getLocationModel(params = {}) {
        this.locationLoading = true;
        this.getScanModel({ endPoint: 'location-statistics', params }).then(resp => {
        this.locationModel = resp || [];
        this.locationLoading = false;
        setTimeout(() => {
            this.locationChartInitiated();
        });
        }).catch(err => this.locationLoading = false);
    },
    async chartInitiated() {
        // Create chart instance
        let root = am5.Root.new(this.$refs.chartdiv);

        //Remove Amchart Logo From Leftside Bottom
        root._logo.dispose();

        // Themes begin
        root.setThemes([am5themes_Animated.new(root)]);

        // Create container
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));

        // Create axes
        const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "agent_name",
            renderer: am5xy.AxisRendererY.new(root, {
                inversed: true,
                cellStartLocation: 0.1,
                cellEndLocation: 0.9
            })
        }));
        yAxis.data.setAll(this.model);

        const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, {}),
            min: 0
        }));
        const chartData = {
            axes : { xAxis: xAxis, yAxis: yAxis},
            chart: chart,
            data: this.model
        }

        // Add cursor
        const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomY"
        }));
        cursor.lineY.set("forceHidden", true);
        cursor.lineX.set("forceHidden", true);

        // Add series
        await this.createSeries("arch", root, chartData, 'agent_name');
        await this.createSeries("chasis", root, chartData, 'agent_name');
        await this.createSeries("tire", root, chartData, 'agent_name');

        // Add legend
        if (this.model.length) {
        const legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
            marginTop: 40
        }))
        legend.data.setAll(chart.series.values);
        }

        // Make stuff animate on load
        chart.appear(1000, 100);

        this.chart = chart;
    },
    async locationChartInitiated() {
        // Create chart instance
        let root = am5.Root.new(this.$refs.locationChartdiv);

        //Remove Amchart Logo From Leftside Bottom
        root._logo.dispose();

        // Themes begin
        root.setThemes([am5themes_Animated.new(root)]);

        // Create container
        let chart = root.container.children.push(am5xy.XYChart.new(root, {
            panX: false,
            panY: false,
            wheelX: "panX",
            wheelY: "zoomX",
            layout: root.verticalLayout
        }));

        // Create axes
        const yAxis = chart.yAxes.push(am5xy.CategoryAxis.new(root, {
            categoryField: "location_name",
            renderer: am5xy.AxisRendererY.new(root, {
            inversed: true,
            cellStartLocation: 0.1,
            cellEndLocation: 0.9
            })
        }));
        yAxis.data.setAll(this.locationModel);

        const xAxis = chart.xAxes.push(am5xy.ValueAxis.new(root, {
            renderer: am5xy.AxisRendererX.new(root, {}),
            min: 0
        }));
        const chartData = {
            axes : { xAxis: xAxis, yAxis: yAxis},
            chart: chart,
            data: this.locationModel
        }

        // Add cursor
        const cursor = chart.set("cursor", am5xy.XYCursor.new(root, {
            behavior: "zoomY"
        }));
        cursor.lineY.set("forceHidden", true);
        cursor.lineX.set("forceHidden", true);

        // Add series
        await this.createSeries("arch", root, chartData, 'location_name');
        await this.createSeries("chasis", root, chartData, 'location_name');
        await this.createSeries("tire", root, chartData, 'location_name');

        // Add legend
        if (this.locationModel.length) {
            const legend = chart.children.push(am5.Legend.new(root, {
            centerX: am5.p50,
            x: am5.p50,
            marginTop: 40
            }))
            legend.data.setAll(chart.series.values);
        }

        // Make stuff animate on load
        chart.appear(1000, 100);

        this.locationChart = chart;
    },
    createSeries(field, root, chartData, categoryfield=null) {
      let series = chartData.chart.series.push(am5xy.ColumnSeries.new(root, {
        name: _.startCase(_.toLower(field)),
        valueXField: field,
        categoryYField: categoryfield,
        sequencedInterpolation: true,
        ...chartData.axes,
        tooltip: am5.Tooltip.new(root, {
          pointerOrientation: "horizontal",
          labelText: "[bold]{categoryY}[/]\n{name}: {valueX}"
        })
      }));

      series.columns.template.setAll({
        height: am5.p100
      });

      series.bullets.push(() => am5.Bullet.new(root, {
        locationX: 1,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          centerY: am5.p50,
          text: "{valueX}",
          populateText: true
        })
      }));
      series.bullets.push(() => am5.Bullet.new(root, {
        locationX: 1,
        locationY: 0.5,
        sprite: am5.Label.new(root, {
          centerX: am5.p100,
          centerY: am5.p50,
          text: "{name}",
          fill: am5.color(0xffffff),
          populateText: true
        })
      }));

      series.data.setAll(chartData.data);
      series.appear();

      return series;
    }
  },
  beforeDestroy() {
    if (this.chart) {
      this.chart.dispose();
    }
    if (this.locationChart) {
      this.locationChart.dispose();
    }
  }
};
</script>
